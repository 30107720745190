<script lang="ts" setup>
import type { MenuItem } from '@/layouts/VoixMarketing.vue'
import type Lenis from 'lenis'

defineProps<{
  menuItems: MenuItem[]
}>()

const lenis = inject<Ref<null | Lenis>>('lenis')

async function goToAnchor(to: string) {
  const id = to.replace('#', '').replace('/', '')
  const el = document.getElementById(id)

  if (el) {
    lenis?.value?.scrollTo(el)
  }
  else {
    await navigateTo(to)
  }
}
</script>

<template>
  <div class="relative font-lbm-sans">
    <!-- Main menu -->
    <div class="relative z-60 w-full mx-auto px-12 lg:px-24  border-b border-stone-800">
      <div class="mx-auto w-full flex justify-between items-stretch h-20  divide-x divide-stone-800">
        <NuxtLink to="/" class="block translate-y-1.5 h-20" aria-label="Link to go Home">
          <LbmLogo class="h-10 translate-x-1 translate-y-3.5" mode="both" direction="horizontal" animate-on-hover />
        </NuxtLink>
        <ul class="hidden lg:flex items-center text-lg font-lbm-serif">
          <li v-for="(menuItem, n) in menuItems" :key="menuItem.to" class="px-12 h-full flex items-center">
            <button v-if="menuItem.to" class="text-stone-200" @click="goToAnchor(menuItem.to)">
              <span class="italic">{{ n + 1 }}.</span> {{ menuItem.title }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
.menu-enter-active,
.menu-leave-active {
  transition: all 0.2s;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;
  transform: scale(0.98);
  filter: blur(1rem);
}

.submenu-rect {
  @apply stroke-stone-400/40;
  stroke-width: 3px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.fade-leave-active {
  transition: opacity 0.2s ease-out;
  transition-delay: 1s;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
